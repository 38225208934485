*,
*::before,
*::after {
  box-sizing: border-box;
}

input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background-image: linear-gradient(#ff4500, #ff4500);
    border-radius: 1px;
    border: 0px solid #000000;
  }
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #eee;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background-image: linear-gradient(#ff4500, #ff4500);
  cursor: pointer !important;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background-image: linear-gradient(#ff4500, #ff4500);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #eee;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background-image: linear-gradient(#ff4500, #ff4500);
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background-image: linear-gradient(#ff4500, #ff4500);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background-image: linear-gradient(#ff4500, #ff4500);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #ff4500;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background-image: linear-gradient(#ff4500, #ff4500);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #ff4500;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ff4500;
}


input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 200px;
    height: 7px;
      border-radius: 5px;
    background-image: linear-gradient(#ff4500, #ff4500);
    background-size: 70% 100%;
    background-repeat: no-repeat;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ff4500;
    cursor:pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  @media(max-width:768px) {
  .m-b-sm {
    margin-bottom:10px;
  }
}
:root {
  --input-border: #8b8a8b;
  --input-focus-h: 245;
  --input-focus-s: 100%;
  --input-focus-l: 42%;
}

.input {
  font-size: 16px;
  font-size: max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  transition: 180ms box-shadow ease-in-out;
}

.input:focus {
  border-color: hsl(var(--input-focus-h), var(--input-focus-s), var(--input-focus-l));
  box-shadow: 0 0 0 3px hsla(var(--input-focus-h), var(--input-focus-s), calc(var(--input-focus-l) + 40%), 0.8);
  outline: 3px solid transparent;
}

.slider_component {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.punks-slider_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1rem;
  padding-bottom:1rem;
}

.punks-slider_list {
  display: -ms-grid;
  display: grid;
  margin-right: 1rem;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  justify-items: start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 10rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  -ms-grid-columns: 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem;
  grid-template-columns: 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  white-space: nowrap;
}

@media(max-width: 768px) {
  .input-range-label {display:flex;}
}

.punks-slider_image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.punks-slider_image {
  width: 10rem;
  height: 10rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.grid-2 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.input:not(textarea) {
  line-height: 1;
  height: 2.25rem;
}

input[type=file] {
  font-size: 0.9em;
  padding-top: 0.35rem;
}

textarea.input {
  resize: vertical;
}

.input[readonly] {
  border-style: dotted;
  cursor: not-allowed;
  color: #777;
}

.input[disabled] {
  --input-border: #ccc;
  background-color: #eee;
  cursor: not-allowed;
}

label {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
}

.input + label {
  margin-top: 2rem;
}
